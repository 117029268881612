<template>
  <main class="page-login d-flex align-items-center justify-content-center flex-column">
    <AwlModalCard title="Accedi" :border="false">
      <LoginForm disable-signup />
    </AwlModalCard>
    <div class="financers d-flex align-items-center justify-content-center bg-white rounded-lg">
      <img src="../../../assets/images/financers-logo.svg">
    </div>
  </main>
</template>

<script>

export default {
  name: 'page-login',
  components: {
    AwlModalCard: () => import('../../components/Card/AwlModalCard'),
    LoginForm: () => import('../../components/Account/LoginForm'),
  },
};
</script>

<style lang="scss">
.page-login {
  background-image: url($path-images + '/login-background.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  > * {
    position: relative;
    z-index: 1;
  }
  &::after {
    background: rgb(0,0,0);
    background: linear-gradient(0deg,rgba(0,0,0,.8),rgba($primary,.1));
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .financers {
    position: absolute;
    bottom: 30px;
    padding: 15px;

    img {
      width: 600px;
    }
  }
}
</style>
